.text-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: wrap;
  /* text-align: justify; */
}

.whychooseus .swiper {
  max-width: 1000px;
  max-height: 450px;
  border-radius: 20px;
}
.whychooseus .hero-body p {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

.whychooseus .improving-body p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 40px;
}

.whychooseus .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: var(--primary-color);
  padding: 0px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.whychooseus .swiper-pagination-bullet-active {
  background-color: var(--accent-color) !important;
}

.whychooseus  .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.whychooseus .image-anime{
  border-radius: 10px;
}

.whychooseus .why {
  color: var(--accent-color) !important;
}

.ourclient .medical-service-item {
  position: relative;
  border: 1px solid var(--primary-color);
  margin-bottom: 30px;
  height: calc(100% - 30px);
  z-index: 1;  
  border-radius: 10px;  
  background-color: var(--body-background); 

}

/* .ourclient .medical-service-item:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 100%;
  border-radius: 10px;
  background: var(--accent-color-tansparent);
  transition: all 0.5s ease-in-out;
  z-index: -1;
} */

.ourclient .medical-service-item:hover::before {
  top: 0;
}


.ourclient .medical-service-content {
  padding: 40px;
  border-radius: 10px; 
  height: calc(100% - 30px);
}

.ourclient .medical-service-item {
  margin-bottom: 0px;
}

.ourclient .medical-service-content .icon-box img {
  width: 100%;
}
