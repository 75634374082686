/* Base styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.reflective-section {
  background: #f3f3f3;
  /* fallback for old browsers */
  min-height: 100vh;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reflective-container {
  background: white;
  /* border-radius: 1rem; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  padding: 2rem;
  width: 100%;
  /* max-width: 800px; */
}

.reflective-heading {
  font-size: 2em;
  text-align: center;
  margin: 0 0 1rem 0;
  color: black;
}

.reflective-subheading {
  font-size: 1em;
  text-align: center;
  margin: 0 0 1.5rem 0;
  color: black;
  font-weight: bold;
}

/* FAQ styles */
.faq-list .faq {
  margin-bottom: 1.5rem;
}

.faq-list .faq .faq-header {
  padding: 1rem;
  cursor: pointer;
  background: #f7f7f7;
  border-radius: 0.5rem;
  transition: background 0.3s;
}

.faq-list .faq .faq-header:hover {
  background: #e7e7e7;
}

.faq-list .faq .faq-header .faq-title {
  color: black;
  font-size: 1.1em;
  margin: 0;
}

.faq-list .faq .faq-body {
  padding: 1rem;
  background: #ffffff;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.faq-list .faq .faq-body .faq-text {
  /* text-align: justify; */
  font-size: 1em;
  color: black;
}

/* Print styles */
@media print {
  body {
    margin: 0;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .reflective-section {
    background: white;
    min-height: auto;
    padding: 0;
    display: block;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .reflective-container {
    width: 100%;
    max-width: none;
    border-radius: 0;
    box-shadow: none;
    padding: 1rem;
    page-break-after: always;
  }

  .reflective-heading,
  .reflective-subheading,
  .faq-title,
  .faq-text {
    color: black;
  }
}