.t {
  transform-origin: bottom left;
  z-index: 2;
  position: absolute;
  white-space: pre;
  overflow: visible;
  line-height: 1.5;
  text-wrap: wrap;
}

.text-container {
  white-space: pre;
}

@supports (-webkit-touch-callout: none) {
  .text-container {
    white-space: normal;
  }
}

#t1_1 {
  left: 209px;
  bottom: 944px;
  letter-spacing: -0.13px;
  word-spacing: -1.03px;
}

#t2_1 {
  left: 125px;
  bottom: 309px;
  letter-spacing: 0.14px;
  word-spacing: -1px;
}

#t3_1 {
  left: 125px;
  bottom: 287px;
  letter-spacing: -0.22px;
  word-spacing: -0.24px;
}

#t4_1 {
  left: 434px;
  bottom: 287px;
  letter-spacing: -0.29px;
}

#t5_1 {
  left: 488px;
  bottom: 287px;
  letter-spacing: -0.27px;
  word-spacing: 0.04px;
}

#t6_1 {
  left: 494px;
  bottom: 239px;
  letter-spacing: -0.06px;
  word-spacing: -0.67px;
}

#t7_1 {
  left: 531px;
  bottom: 216px;
  letter-spacing: -0.09px;
  word-spacing: -0.52px;
}

#t8_1 {
  left: 113px;
  bottom: 142px;
  word-spacing: -0.2px;
}

#t9_1 {
  left: 334px;
  bottom: 922px;
  letter-spacing: 0.21px;
  word-spacing: -0.66px;
}

#ta_1 {
  /* left: 280px; */
  bottom: 881px;
  letter-spacing: 0.01px;
  word-spacing: 0.05px;
}

#tb_1 {

  bottom: 854px;
  letter-spacing: -0.1px;
  word-spacing: 0.34px;
}

#tc_1 {
  left: 218px;
  bottom: 823px;
  letter-spacing: 0.13px;
  word-spacing: -0.35px;
}

#td_1 {
  left: 270px;
  bottom: 787px;
  letter-spacing: -0.13px;
  word-spacing: 0.08px;
}

#te_1 {
  left: 387px;
  bottom: 753px;
  letter-spacing: -0.21px;
  word-spacing: -0.15px;
}

#tf_1 {
  left: 303px;
  bottom: 724px;
  letter-spacing: -0.14px;
  word-spacing: -0.42px;
}

#tg_1 {
  left: 122px;
  bottom: 655px;
  letter-spacing: -0.25px;
}

#th_1 {
  left: 149px;
  bottom: 634px;
  letter-spacing: 0.17px;
  word-spacing: 0.13px;
}

#ti_1 {
  left: 149px;
  bottom: 616px;
  letter-spacing: 0.16px;
  word-spacing: 0.26px;
}

#tj_1 {
  left: 149px;
  bottom: 598px;
  letter-spacing: 0.16px;
  word-spacing: -0.05px;
}

#tk_1 {
  left: 117px;
  bottom: 559px;
  letter-spacing: -0.26px;
}

#tl_1 {
  left: 134px;
  bottom: 530px;
  letter-spacing: 0.22px;
  word-spacing: -0.05px;
}

#tm_1 {
  left: 317px;
  bottom: 530px;
  letter-spacing: 0.11px;
  word-spacing: 0.04px;
}

#tn_1 {
  left: 430px;
  bottom: 530px;
  letter-spacing: 0.12px;
  word-spacing: 0.35px;
}

#to_1 {
  left: 117px;
  bottom: 489px;
  letter-spacing: -0.28px;
  word-spacing: 0.55px;
}

#tp_1 {
  left: 122px;
  bottom: 456px;
  letter-spacing: -0.11px;
  word-spacing: -0.59px;
}

#tq_1 {
  left: 135px;
  bottom: 415px;
  letter-spacing: -0.08px;
  word-spacing: -0.68px;
}

#tr_1 {
  left: 150px;
  bottom: 389px;
  letter-spacing: -0.08px;
  word-spacing: -0.7px;
}

.s0 {
  font-size: 40px;
  font-family: sub_Arial-BoldMT_lsb;
  color: #000;
}

.s1 {
  font-size: 18px;
  font-family: sub_Arial-BoldMT_lsb;
  color: #000;
}

.s2 {
  font-size: 17px;
  font-family: sub_ArialMT_lsr;
  color: #000;
}

.s3 {
  font-size: 17px;
  font-family: ArialMT_1r;
  color: #000;
}

.s4 {
  font-size: 21px;
  font-family: sub_Arial-BoldMT_lsb;
  color: #000;
}

.s5 {
  font-size: 21px;
  font-family: LucidaSansUnicode_1v;
  color: #000;
}

.s6 {
  font-size: 20px;
  font-family: sub_Arial-BoldMT_lsb;
  color: #000;
}

.s7 {
  font-size: 15px;
  font-family: sub_ArialMT_lsr;
  color: #000;
}

.certificateDetails {
  width: 100%;
  position: absolute;
  top: 300px;
  left: 0;
  /* left: 50%;
  transform: translateX(-50%); */
  text-align: center;
  z-index: 1;
  text-wrap: wrap;
  font-family: sub_ArialMT_lsr;
}

.certificateDetails .font1 {
  font-family: LucidaSansUnicode_1v;
}

.certificateDetails .font2 {
  font-family: sub_Arial-BoldMT_lsb;
}

.certificateDetails p,
.certificateDetails ul {
  margin-bottom: 5px !important;
  font-size: 16px;
}

.certificateDetails h1,
.certificateDetails h2,
.certificateDetails h3,
.certificateDetails h4,
.certificateDetails h5,
.certificateDetails h6 {
  color: black;
}

.certificateDetails .dark-text {
  font-weight: 600;
  font-size: 17px;
}

.aimsDiv {
  text-align: left;
  width: 80%;
  margin: auto;
}

.declairDiv {
  width: 33%;
  margin: 10px 10% 10px auto;
  margin-right: 10%;
}