/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/******************************************/
/****   	11. Why Choose Us css  	   ****/
/******************************************/
@font-face {
  font-family: ArialMT_1r;
  src: url("./fonts/ArialMT_1r.woff") format("woff");
}

@font-face {
  font-family: LucidaSansUnicode_1v;
  src: url("./fonts/LucidaSansUnicode_1v.woff") format("woff");
}

@font-face {
  font-family: sub_Arial-BoldMT_lsb;
  src: url("./fonts/sub_Arial-BoldMT_lsb.woff") format("woff");
}

@font-face {
  font-family: sub_ArialMT_lsr;
  src: url("./fonts/sub_ArialMT_lsr.woff") format("woff");
}
.why-choose-us {
  padding: 100px 0;
  background: var(--secondary-color);
}

.why-choose-item {
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  padding: 25px;
  height: 100%;
  color: var(--white-color);
  background-color: var(--primary-color);
}

/* .why-choose-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--white-color);
  z-index: 3;
  border-radius: 30px;
  transition: all 1.5s ease-out;
} */

.why-choose-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 36px;
  overflow: hidden;
  transition: all 0.1s ease-out;
}

.why-choose-image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(111, 112, 110, 0.65);
}

.why-choose-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.why-choose-content {
  position: relative;
  z-index: 4;
}

.why-choose-content .why-choose-icon {
  margin-bottom: 20px;
}

.why-choose-content .why-choose-icon img {
  max-height: 60px;
}

.why-choose-content h3 {
  font-size: 22px;
  transition: all 0.3s ease-out;
}

.why-choose-content p {
  margin-bottom: 0;
  transition: all 0.3s ease-out;
}

.why-choose-item:hover:before {
  transform: translate(100%, -100%);
}

.why-choose-item:hover .why-choose-image {
  opacity: 1;
}

.why-choose-item:hover .why-choose-content h3,
.why-choose-item:hover .why-choose-content p {
  color: var(--white-color);
}

.why-chooseus-layout2 .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.why-chooseus-layout2 .why-choose-us-img-box {
  position: relative;
  /* background: url(../images/whyus-left-img.jpg) no-repeat center center; */
  background-size: cover;
  padding: 100px 15px;
  height: 100%;
  min-height: 300px;
}

.why-choose-us-layout2-content {
  padding: 100px;
}

.why-choose-us-layout2-content .section-title {
  text-align: left;
  margin-bottom: 20px;
}

.why-choose-us-layout2-content .why-choose-us-body {
  margin-bottom: 40px;
}

.why-choose-us-layout2-features .why-features-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 40px;
}

.why-choose-us-layout2-features .why-features-item:last-child {
  margin-bottom: 0;
}

.why-choose-us-layout2-features .why-features-item .icon-box {
  width: 60px;
  height: 60px;
  border-radius: 20px;
  background: var(--accent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.why-choose-us-layout2-features .why-features-item .why-features-desc {
  width: calc(100% - 80px);
}

.why-choose-us-layout2-features .why-features-item .why-features-desc h3 {
  font-size: 22px;
  margin-bottom: 0;
}

.why-choose-us-layout2-features .why-features-item .why-features-desc p {
  margin: 0;
}

.why-choose-us-layout3 {
  padding: 100px 0;
  background: var(--secondary-color);
}

.why-choose-item-visible {
  visibility: unset !important;
}

.why-choose-item3 {
  background: var(--white-color);
  text-align: center;
  padding: 40px 30px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.why-choose-item3:before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: -100%;
  width: 100%;
  height: 100%;
  background: var(--primary-color);
  border-radius: 30px;
  transition: all 0.4s ease-in-out;
}

.why-choose-item3 .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto 30px;
  background: var(--accent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  position: relative;
  z-index: 1;
}

.why-choose-item3 h3 {
  font-size: 22px;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.why-choose-item3 p {
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.why-choose-item3:hover:before {
  top: 0;
  left: 0;
}

.why-choose-item3:hover h3,
.why-choose-item3:hover p {
  color: var(--white-color);
}

.what-we-provide-scroll-box {
  height: 480px !important;
  border-radius: 10px !important;
}

.main-page-card {
  margin-top: 20px;
}

@media only screen and (max-width: 1400px) {
  .what-we-provide-scroll-box {
    height: 500px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .what-we-provide-scroll-box {
    height: 550px !important;
  }

  .main-page-card {
    transform: translate(0px, 50px);
  }
}

@media only screen and (max-width: 1100px) {
  .what-we-provide-scroll-box {
    height: 600px !important;
  }
}

@media only screen and (max-width: 1050px) {
  .what-we-provide-scroll-box {
    height: 650px !important;
  }
}

@media only screen and (max-width: 992px) {
  .what-we-provide-scroll-box {
    height: 400px !important;
  }
}

@media only screen and (max-width: 900px) {
  .what-we-provide-scroll-box {
    height: 450px !important;
  }
}

@media only screen and (max-width: 850px) {
  .what-we-provide-scroll-box {
    height: 500px !important;
  }
}

@media only screen and (max-width: 768px) {
  .what-we-provide-scroll-box {
    height: 300px !important;
  }
}

@media only screen and (max-width: 650px) {
  .what-we-provide-scroll-box {
    height: 350px !important;
  }
}

@media only screen and (max-width: 500px) {
  .what-we-provide-scroll-box {
    height: 400px !important;
  }
}

@media only screen and (max-width: 450px) {
  .what-we-provide-scroll-box {
    height: 450px !important;
  }
}

@media only screen and (max-width: 415px) {
  .what-we-provide-scroll-box {
    height: 500px !important;
  }
}

@media only screen and (max-width: 372px) {
  .what-we-provide-scroll-box {
    height: 550px !important;
  }
}

@media only screen and (max-width: 350px) {
  .what-we-provide-scroll-box {
    height: 630px !important;
  }
}

@media only screen and (max-width: 318px) {
  .what-we-provide-scroll-box {
    height: 710px !important;
  }
}

/* Login and Register page */

.form-bg {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("https://coolbackgrounds.io/images/backgrounds/index/ranger-4df6c1b6.png");
}
